import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import FigmaEmbed from 'components/FigmaEmbed';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`Checkboxes allow the user to select any number of choices, including zero items,
one item or multiple items.`}</p>
    <ul>
      <li parentName="ul">{`Checkboxes should always be accompanied by a label description`}</li>
      <li parentName="ul">{`Use a fieldset to group multiple checkboxes`}</li>
      <li parentName="ul">{`In a form, checkboxes should always be placed underneath each other for better
scanability`}</li>
      <li parentName="ul">{`Checkboxes should be listed in a logical order`}</li>
    </ul>
    <h2 {...{
      "id": "behaviour",
      "style": {
        "position": "relative"
      }
    }}>{`Behaviour`}</h2>
    <p>{`When the description label is too long, it wraps to another line.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=513%3A47" mdxType="FigmaEmbed" />
    <p>{`A checkbox has a minimum target area of 44x44px around its field`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=513%3A99" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best Practices`}</h2>
    <h3 {...{
      "id": "stand-alone-checkboxes",
      "style": {
        "position": "relative"
      }
    }}>{`Stand-alone checkboxes`}</h3>
    <p>{`Use a stand-alone checkbox for a single option that the user can turn on or off.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=513%3A148" mdxType="FigmaEmbed" />
    <h3 {...{
      "id": "grouping-checkboxes",
      "style": {
        "position": "relative"
      }
    }}>{`Grouping checkboxes`}</h3>
    <p>{`A group of checkboxes should always be accompanied by a fieldset.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=513%3A191" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "related-components",
      "style": {
        "position": "relative"
      }
    }}>{`Related components`}</h2>
    <ul>
      <li parentName="ul">{`If a user has to make a `}<strong parentName="li">{`single`}</strong>{` choice, use the
`}<a parentName="li" {...{
          "href": "/components/radio/code"
        }}>{`radio`}</a>{` or `}<a parentName="li" {...{
          "href": "/components/choice/code"
        }}>{`choice radio`}</a>{`
components.`}</li>
      <li parentName="ul">{`If the user has to make a `}<strong parentName="li">{`single`}</strong>{` choice where there are `}<strong parentName="li">{`more than 4
options`}</strong>{`, use the `}<a parentName="li" {...{
          "href": "/components/select/code"
        }}>{`select`}</a>{` component.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      